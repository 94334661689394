<template>
  <div class="select-books-wrapper">
      <div class="sliding-nav">
      <ul class="nav nav-tabs" v-if="isLoaded">
        <li v-for="(tab, key) in tabs" :key="key">
          <a
            class="tab-heads"
            :class="{ active: active === tab.id, 'tab-small': small}"
            @click="switchTab(tab.id)"
            >
              <slot :name="tabHeadSlotName(tab.id)">
                {{tab.name}}
              </slot>
            </a
          >
        </li>
      </ul>
    </div>
    <div>
    <div class="tab-content">
      <div class="growing-cover-slider tab-pane-slider swiper-container">
        <div>
          <slot :name="tabPanelSlotName" v-if="this.books.length">
            <TrainingSelectBooksSlider v-show="isLoadedBooks" :props="books" :id="tabPanelSlotName" :shelfId="active"/>
          </slot>
          <slot :name="tabPanelSlotName" v-else>
            <Loader />
          </slot>
        </div>
        <div class="container" v-if="this.books.length && isLoadedBooks">
          <div class="profile-book-name text-center mt-3">
            <div class="book-heading-small">{{bookData.autor}}</div>
          </div>
          <div class="text-center">
            <div class="book-heading-big ">{{bookData.tytul}}</div>
        </div>
        </div>
        <div  class="d-flex w-100 justify-content-center align-items-center" v-if="!isLoadedBooks">
          <div>
            <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
          </div>
        </div>
        <div class="container">
            <button class="btn-black-full w-100 mt-3" @click.prevent="saveBooks">Dalej</button>
      </div>
      </div>
    </div>
    <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="close(false)">
      <div>
        <div class="text-center mb-4 modal-main-title" v-if="booksTotal > selected.length">Wybrano za mało książek</div>
        <div class="text-center mb-4 modal-main-title" v-if="booksTotal < selected.length">Wybrano za dużo książek</div>
        <div class="text-center">Ilość książek w planie: {{booksTotal}}</div>
        <div class="text-center">Ilość wybranych książek: {{selected.length}}</div>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="close(false)">Zamknij</button>
      </div>
    </ModalSlot>
    <ModalSlot title="Dodaj książkę" v-if="isPopup" @close-modal="close(false)">
      <div>
        <div class="text-center mb-4 modal-main-title">Wybierz książki do planu</div>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="close(false)">Zamknij</button>
      </div>
    </ModalSlot>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import TrainingSelectBooksSlider from './TrainingSelectBooksSlider'
import ModalSlot from '../components/ModalSlot'
import Loader from './Loader'
export default {
  name: 'TabsSelectBooksInPlan',
  components: {
    // UserProfileSlider,
    Loader,
    TrainingSelectBooksSlider,
    ModalSlot
  },
  props: {
    tabs: Array,
    activeTab: String,
    small: Boolean,
    selectedSlides: Array,
    activeBook: Array,
    planid: [Number, String]
  },
  data () {
    return {
      active: this.activeTab,
      isLoaded: false,
      isLoadedBooks: false,
      selectedBooks: this.selectedSlides,
      bookData: { autor: '', tytul: '', rating: 0 },
      books: [],
      selected: [],
      sendSelected: [],
      planData: [],
      booksTotal: 0,
      isPopupShown: false,
      isPopup: false
    }
  },
  async created () {
    await this.getBooks()
    EventBus.$on('selected-books-title', (payload) => {
      this.updateData(payload)
    })
    EventBus.$on('selected-to-plan', (payload) => {
      this.updateSelected(payload)
    })
  },
  computed: {
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    selectedToPlan: {
      get () {
        return this.$store.state.selectedToPlan
      },
      set (value) {
        this.$store.commit('updateSelectedToPlan', value)
      }
    }
  },
  methods: {
    close () {
      this.isPopupShown = false
      this.isPopup = false
    },
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName) {
      this.active = tabName
      this.getBooks()
    },
    updateData (payload) {
      this.bookData = payload
    },
    updateSelected (payload) {
      this.selected = payload.map(e => e.id)
    },
    saveBooks () {
      if (this.planid && this.selected.length) {
        if (this.planData.type === 'books') {
          if (parseInt(this.selected.length) !== parseInt(this.booksTotal)) {
            this.isPopupShown = true
          } else {
            this.sendBooksData()
          }
        } else {
          this.sendBooksData()
        }
      } else {
        this.isPopup = true
      }
    },
    sendBooksData () {
      const getData = `books=${this.selected}`
      this.$https('/plan/' + this.planid + '/books', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$router.push('/podsumowanie-planu')
      })
        .catch(error => {
          console.log('', error)
        })
    },
    getBooks () {
      this.isLoadedBooks = false
      this.$https('/shelf-books/' + this.active, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          this.books = res.data.response[0].items
          this.isLoaded = true
          this.isLoadedBooks = true
        })
        .catch(error => {
          console.log('', error)
        })

      this.$https('/plans', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.planData = data.response[data.response.length - 1]
          this.booksTotal = this.planData.books_total
        })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  beforeDestroy () {
    EventBus.$off('selected-books-title', (payload) => {
      this.updateData(payload)
    })
    // EventBus.$off('selected-to-plan', (payload) => {
    //   this.updateSelected(payload)
    // })
  }
}
</script>
<style lang="scss" scoped>
  .tab-small {
    font-size: 16px;
  }
  .select-books-wrapper {
    margin-bottom: 50px;
  }
</style>
