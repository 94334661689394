<template>
  <div>
    <div v-if="!isLoaded"><Loader /></div>
    <div v-if="isLoaded" class="app-content-box bg-gray">
      <div v-if="shelves.length">
       <div class="container">
          <div class="top-heading-small text-center mt-3">Moja biblioteka</div>
        </div>
        <TabsSelectBooksInPlan :activeTab="activeTab" :tabs="shelves" :friends="friends" v-if="isLoaded" :planid="plan_id">
          <div v-for="(shelf, key) in shelves" :key="key">
          <template :slot="'tab-head-' + shelf.id" >
          </template>
          <template :slot="'tab-panel-' + shelf.id">
          </template>
        </div>
        </TabsSelectBooksInPlan>
      </div>
      <div v-else>
        <EmptyShelf :plan="true"/>
      </div>
    </div>
  </div>
</template>
<script>
import TabsSelectBooksInPlan from '../components/TabsSelectBooksInPlan'
import Loader from '../components/Loader'
import EmptyShelf from '../components/EmptyShelf'
export default {
  name: 'UserProfileLibrary',
  props: {
    plan_id: [Number, String]
  },
  components: {
    TabsSelectBooksInPlan,
    Loader,
    EmptyShelf
  },
  async created () {
    await this.$https('/shelf?forPlan=1', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        const books = res.data.response
        books.forEach(e => {
          if (e.book_count > 0) {
            this.tabs.push(e.name)
          }
        })
        books.forEach(e => {
          if (e.book_count > 0) {
            this.shelves.push({
              id: e.id,
              name: e.name
            })
          }
        })
        if (!this.shelves.length) {
          this.isLoaded = true
        } else {
          this.activeTab = this.shelves[0].id
          this.isLoaded = true
        }
      })
      .catch(err => {
        console.log('', err)
      })
  },
  data () {
    return {
      isLoaded: false,
      activeTab: '',
      shelves: [],
      tabs: [],
      friends: []
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
</style>
