<template>
  <div class="container wrapper-empty">
    <div class="book-heading-big text-center mt-2">Brak książek na półce</div>
    <div class="empty-shelf-icon text-center mt-3">
      <img src="../assets/app_icon/pusta-polka.png" alt="pusta-polka"/>
    </div>
    <div v-if="this.top === true" class="book-heading-medium text-center mt-3">Wyszukaj lub zeskanuj książki, aby stworzyć swoje TOP 5</div>
    <div v-else-if="this.plan === true" class="book-heading-medium text-center mt-5">Wyszukaj lub zeskanuj książki, aby dodać je do planu</div>
    <div v-else class="book-heading-medium text-center mt-3">Wyszukaj lub zeskanuj książki, aby umieścić je w swojej bibliotece</div>
    <router-link to="/wyszukiwanie"><button class="btn-black-full w-100 mt-3">Wyszukaj książki</button></router-link>
    <div v-if="platform !== 'ios'"><router-link to="/skanuj"><button class="btn-black-full w-100 mt-2"><a href="#" class="text-white">Zeskanuj książkę</a></button></router-link></div>
  </div>
</template>
<script>
export default {
  name: 'EmptyShelf',
  props: {
    top: Boolean,
    plan: Boolean
  },
  data () {
    return {
      platform: window.platform
    }
  }
}
</script>
<style lang="scss" scoped>
  .empty-shelf-icon img {
    width: 150px;
    height: 150px;
  }
</style>
