<template>
<div>
    <swiper  ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="sendData">
      <div class="growing-slide swiper-slide slide-wrapper"
      v-for="(book, key) in allBooks" :key="id + key" :id="id"
       :class="{checked: selectedBooks.filter(function(item) { return item.control == id + key }).length > 0}">
        <swiper-slide>
            <div class="growing-slide-cover-container books-featured-slide-cover-container"
            :class="{'checked-mark': selectedBooks.filter(function(item) { return item.control == id + key }).length > 0}"
            @click="selectBook(key)">
              <div class="growing-slide-cover"
              :class="{'cover checked-cover': selectedBooks.filter(function(item) { return item.control == id + key }).length > 0}">
                <img :src="( book.image ? coverUrl + book.image : coverUrl + 'no_cover.png')" class="slide-cover-image" :alt="book.tytul"/>
              </div>
            </div>
        </swiper-slide>
        </div>
        <div  class="d-flex align-items-center" v-if="isLoading">
         <div>
           <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
         </div>
        </div>
    </swiper>
    <!-- <div  class="d-flex w-100 justify-content-center align-items-center" v-if="isBooksLoading">
      <div>
        <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
      </div>
    </div> -->
</div>
</template>
<script>
import EventBus from '../eventBus'
export default {
  name: 'TrainingSelectBooksSlider',
  props: {
    props: Array,
    id: String,
    shelfId: [String, Number]
  },
  data () {
    return {
      selectedBooks: [],
      allBooks: [],
      slideIndex: 0,
      page: 2,
      isLoading: false,
      isBooksLoading: false,
      counter: 29,
      index: this.id,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  created () {
    this.allBooks = this.props
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    selectedToPlan: {
      get () {
        return this.$store.state.selectedToPlan
      },
      set (value) {
        this.$store.commit('updateSelectedToPlan', value)
      }
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  watch: {
    selectedBooks: function () {
    },
    shelfId: function () {
      this.allBooks = this.props
      this.swiper.slideTo(0, 500, true)
    },
    props: function () {
      this.allBooks = this.props
      this.sendData()
    }
  },
  methods: {
    saveBooks () {

    },
    emitSelected () {
      EventBus.$emit('selected-to-plan', this.selectedBooks)
    },
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      if (this.slideIndex === this.counter && this.shelfId) {
        this.isLoading = true
        this.getMore()
      }
      var el = this.allBooks[this.slideIndex]
      const payload = {
        autor: el.autor,
        tytul: el.tytul
      }
      EventBus.$emit('selected-books-title', payload)
      this.emitSelected()
      // this.selectedToPlan = this.selectedBooks
    },
    getMore () {
      this.$https('/shelf-books/' + this.shelfId + '?page=' + this.page, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          const items = data.response[0].items
          items.forEach(e => {
            this.allBooks.push(e)
          })
          this.isLoading = false
          this.counter = this.counter + 29
          this.page = this.page + 1
        })
        .catch(error => {
          console.log('', error)
        })
    },
    selectBook (key) {
      var arrayItem = this.allBooks[key]
      var control = this.id.toString() + key.toString()
      let isSelected = false
      let toDelete = 0
      this.selectedBooks.forEach(element => {
        if (element.control === control) {
          isSelected = true
          toDelete = this.selectedBooks.indexOf(element)
        }
      })

      if (isSelected === false) {
        this.selectedBooks.push({
          control: control,
          id: arrayItem.id,
          select: true
        })
      } else {
        this.selectedBooks.splice(toDelete, 1)
      }
      this.emitSelected()
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
